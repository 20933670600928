





















import VuReputation from "@/components/chat/reputation/VuReputation.vue";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Vue, Component } from 'vue-property-decorator'

@Component({
  computed: {
    SitePermissionEnum() {
      return SitePermissionEnum
    }
  },
  'components': {
    PermissionsBanner,
    NotAvailableOptionsOverlay,
    VuReputation,
    PageTitle
  },
})
export default class ReputationChart extends Vue {
  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}
